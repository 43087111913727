"use client";

import Link from "next/link";
import { usePathname } from "next/navigation";

interface SidebarIconLinkProps {
  href: string;
  className?: string;
  ariaLabel: string;
  iconName: string;
}

const iconPaths = {
  "conversations-icon": "M4.09417 2.215C5.82333 1.99 7.58583 1.875 9.375 1.875C11.1642 1.875 12.9267 1.99083 14.6558 2.215C16.2575 2.42333 17.3983 3.76583 17.4933 5.32083C17.2147 5.22802 16.926 5.16899 16.6333 5.145C14.2985 4.95118 11.9515 4.95118 9.61667 5.145C7.65167 5.30833 6.25 6.97 6.25 8.84V12.4117C6.24921 13.0981 6.43814 13.7715 6.79593 14.3574C7.15372 14.9432 7.66644 15.4188 8.2775 15.7317L6.06667 17.9417C5.97926 18.029 5.86795 18.0884 5.74678 18.1125C5.62561 18.1365 5.50003 18.1242 5.3859 18.0769C5.27176 18.0296 5.17419 17.9496 5.10551 17.8469C5.03682 17.7443 5.00011 17.6235 5 17.5V14.1417C4.69766 14.1095 4.3957 14.074 4.09417 14.035C2.42083 13.8167 1.25 12.3608 1.25 10.7183V5.53167C1.25 3.89 2.42083 2.4325 4.09417 2.215Z M13.125 6.25C11.9783 6.25 10.8425 6.2975 9.72 6.39084C8.43667 6.4975 7.5 7.58584 7.5 8.84084V12.4117C7.5 13.6675 8.44 14.7567 9.725 14.8617C10.7608 14.9467 11.8083 14.9925 12.865 14.9992L15.1833 17.3167C15.2707 17.404 15.3821 17.4634 15.5032 17.4875C15.6244 17.5115 15.75 17.4992 15.8641 17.4519C15.9782 17.4046 16.0758 17.3246 16.1445 17.2219C16.2132 17.1193 16.2499 16.9985 16.25 16.875V14.8833L16.525 14.8617C17.81 14.7575 18.75 13.6675 18.75 12.4117V8.84C18.75 7.58584 17.8125 6.4975 16.53 6.39C15.3974 6.29635 14.2614 6.24964 13.125 6.25Z",
  "campaigns-icon": "M14.386 3.6987A20.6057 19.7184 0 016.6909 5.1107H6.0223a4.6807 4.4791 0 00-.7846 8.896 19.2514 18.4224 0 001.2286 3.3615c.4137.8566 1.4925 1.1262 2.302.6791l.5858-.3234c.7846-.4334 1.0387-1.3591.6883-2.1056a15.2599 14.6028 0 01-.5599-1.371c1.71.2201 3.3523.6399 4.9036 1.2337A19.3709 18.5368 0 0015.3836 9.5898c0-2.0595-.3504-4.0397-.9977-5.8911ZM15.6154 3.1826a20.702 19.8105 0 011.1055 6.4073 20.702 19.8105 0 01-1.2571 6.8185.6687.6399 0 101.2562.4402 21.8922 20.9495 0 001.2616-5.4859 2.6675 2.5527 0 00.7453-1.7729c0-.6885-.2844-1.3139-.7453-1.7729a21.9769 21.0306 0 00-1.2616-5.4859.6687.6399 0 10-1.2562.4402c.0526.1365.1034.2739.1516.4121Z",
  "analytics-icon": "M9.25 4.5C8.01387 4.5 6.8055 4.86656 5.77769 5.55331C4.74988 6.24007 3.9488 7.21619 3.47576 8.35823C3.00271 9.5003 2.87894 10.7569 3.12009 11.9693C3.36125 13.1817 3.95651 14.2953 4.83059 15.1694C5.70466 16.0435 6.81831 16.6388 8.03069 16.8799C9.24307 17.1211 10.49974 16.9973 11.6418 16.5242C12.7838 16.0512 13.7599 15.2501 14.4467 14.2223C15.1334 13.1945 15.5 11.9861 15.5 10.75H9.25V4.5Z M11.75 8.25H18C18 6.5924 17.3415 5.00268 16.1694 3.83058C14.9973 2.65848 13.4076 2 11.75 2V8.25Z",
  "calls-icon": "M6.72368 6.27082L6.72364 6.27065L5.80291 2.58526C5.69834 2.16799 5.32299 1.875 4.89333 1.875H3.75C3.25272 1.875 2.77581 2.07254 2.42417 2.42417C2.07254 2.77581 1.875 3.25272 1.875 3.75V5.625C1.875 12.5282 7.47184 18.125 14.375 18.125H16.25C16.7473 18.125 17.2242 17.9275 17.5758 17.5758C17.9275 17.2242 18.125 16.7473 18.125 16.25V15.1075C18.125 14.6768 17.8319 14.3017 17.4148 14.1971C17.4148 14.1971 17.4148 14.1971 17.4147 14.1971L13.7293 13.2764L13.7292 13.2763C13.5502 13.2315 13.3619 13.2406 13.1881 13.3023C13.0142 13.364 12.8624 13.4757 12.7517 13.6233L12.7516 13.6234L11.9433 14.7009L11.942 14.7026C11.701 15.0215 11.2934 15.1499 10.93367 15.0172C9.56987 14.5156 8.33136 13.7237 7.30385 12.6962C6.27618 11.6685 5.48414 10.42974 4.98257 9.0657C4.85096 8.7081 4.97679 8.29828 5.2991 8.05671L6.72368 6.27082ZM6.72368 6.27082C6.76845 6.44982 6.75941 6.63805 6.69768 6.81194C6.63596 6.98582 6.52429 7.13762 6.37668 7.24832L6.3766 7.24838L5.29976 8.05622L6.72368 6.27082Z",
  "audience-icon": "M6.59002 4.91C6.59002 3.05 8.09002 1.55 9.95002 1.55C11.81 1.55 13.31 3.05 13.31 4.91C13.31 6.77 11.81 8.27 9.95002 8.27C8.09002 8.27 6.59002 6.77 6.59002 4.91ZM13.31 7.59C13.31 6.11 14.51 4.91 16 4.91C17.49 4.91 18.69 6.11 18.69 7.59C18.69 9.07 17.49 10.27 16 10.27C14.51 10.27 13.31 9.07 13.31 7.59ZM1.31002 7.59C1.31002 6.11 2.51002 4.91 4.00002 4.91C5.49002 4.91 6.69002 6.11 6.69002 7.59C6.69002 9.07 5.49002 10.27 4.00002 10.27C2.51002 10.27 1.31002 9.07 1.31002 7.59ZM4.95002 12.41C6.02002 10.71 7.89002 9.59 9.99002 9.59C12.09 9.59 13.96 10.71 15.03 12.41C15.77 13.58 16.09 14.98 15.94 16.35C15.91 16.57 15.79 16.76 15.6 16.87C13.92 17.83 11.99 18.39 9.91002 18.39C7.83002 18.39 5.90002 17.83 4.22002 16.87C4.03002 16.76 3.91002 16.57 3.88002 16.35C3.73002 14.98 4.05002 13.58 4.95002 12.41ZM3.81002 11.63C3.80002 11.65 3.79002 11.67 3.78002 11.68C2.91002 13.03 2.51002 14.65 2.63002 16.25C2.08002 16.17 1.54002 16.03 1.03002 15.85L0.930019 15.81C0.740019 15.75 0.610019 15.57 0.590019 15.37L0.580019 15.26C0.580019 15.17 0.570019 15.08 0.570019 14.99C0.570019 13.17 2.00002 11.71 3.81002 11.63ZM17.31 16.25C17.43 14.65 17.03 13.03 16.16 11.68C16.15 11.66 16.14 11.65 16.13 11.63C17.94 11.71 19.37 13.17 19.37 14.99C19.37 15.08 19.36 15.17 19.36 15.26L19.35 15.37C19.33 15.57 19.2 15.75 19.01 15.81L18.91 15.85C18.4 16.03 17.86 16.17 17.31 16.25Z",
  "emergency-icon": "M10 0.261597C4.61522 0.261597 0.25 4.62682 0.25 10.0116C0.25 15.3964 4.61522 19.7616 10 19.7616C15.3848 19.7616 19.75 15.3964 19.75 10.0116C19.75 4.62682 15.3848 0.261597 10 0.261597ZM10.75 7.0116C10.75 6.59738 10.4142 6.2616 10 6.2616C9.58579 6.2616 9.25 6.59738 9.25 7.0116V10.7616C9.25 11.1758 9.58579 11.5116 10 11.5116C10.4142 11.5116 10.75 11.1758 10.75 10.7616V7.0116ZM10.75 13.7616C10.75 14.1758 10.4142 14.5116 10 14.5116C9.58579 14.5116 9.25 14.1758 9.25 13.7616C9.25 13.3474 9.58579 13.0116 10 13.0116C10.4142 13.0116 10.75 13.3474 10.75 13.7616Z",
};

export default function SidebarIconLink({ href, className = "", ariaLabel, iconName }: SidebarIconLinkProps) {
  const pathname = usePathname();
  const isPathActive =
    (iconName === "audience-icon" && pathname?.startsWith("/dashboard/campaigns/audiences")) ||
    (iconName === "campaigns-icon" && pathname?.startsWith("/dashboard/campaigns") && !pathname?.startsWith("/dashboard/campaigns/audiences")) ||
    (!pathname?.startsWith("/dashboard/campaigns") && pathname?.split("/").slice(0, 3).join("/") === href.split("/").slice(0, 3).join("/"));

  const baseStyles = "flex items-center justify-center w-10 h-10 rounded-full transition-colors";
  const activeStyles = isPathActive ? "sidebar-icon-active" : "sidebar-icon-inactive";

  return (
    <Link
      href={href}
      className={`${baseStyles} ${activeStyles} ${className}`}
      aria-label={ariaLabel}
    >
      <svg className={`w-6 h-6 sidebar-icon ${iconName}`} viewBox="0 0 20 20">
        <path
          stroke="currentColor"
          strokeWidth="0.3"
          className="icon-path"
          d={iconPaths[iconName as keyof typeof iconPaths]}
          fillRule="evenodd"
        />
      </svg>
    </Link>
  );
}
