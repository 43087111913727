"use client";

import React from "react";
import { LogOut, Settings } from "lucide-react";
import { getCookie } from "cookies-next";
import SidebarIconLink from "@/app/dashboard/side-bar/dashboard-sidebar-icon";
import env from "@/lib/env-public";
import TenantSelector from "@/app/dashboard/side-bar/tenant-selector";

export default function Sidebar(props: { qaMode: boolean }) {
  const accountId = getCookie("accountId");
  const isMultiTenant = accountId && env.NEXT_PUBLIC_MULTI_TENANT_ACCOUNT_WHITELIST?.includes(accountId) && getCookie("isMultiTenant") === "true";
  const multiTenantEnabled = isMultiTenant && getCookie("allTenantMode") === "true";

  const showConfig = {
    conversations: true,
    calls: true,
    audiences: !props.qaMode && !multiTenantEnabled,
    campaigns: !props.qaMode && !multiTenantEnabled,
    analytics: !props.qaMode && !multiTenantEnabled,
    emergency: !props.qaMode && !multiTenantEnabled,
    settings: !props.qaMode && !multiTenantEnabled,
  };

  return (
    <div className="w-[72px] h-full bg-transparent shadow-lg flex flex-col justify-between">
      <div className="pt-6 pb-2 flex flex-col items-center">
        <div className="n-logo mb-8"></div>
        <div className="space-y-4 flex flex-col items-center">
          {isMultiTenant && <TenantSelector />}
          {showConfig.conversations && (
            <SidebarIconLink
              href="/dashboard/conversations/all/open"
              ariaLabel="Conversations"
              iconName="conversations-icon"
            />
          )}
          {showConfig.calls && (
            <SidebarIconLink
              href="/dashboard/callboard"
              ariaLabel="Calls"
              iconName="calls-icon"
            />
          )}
          {showConfig.audiences && (
            <SidebarIconLink
              href="/dashboard/campaigns/audiences"
              ariaLabel="Audiences"
              iconName="audience-icon"
            />
          )}
          {showConfig.campaigns && (
            <SidebarIconLink
              href="/dashboard/campaigns"
              ariaLabel="Campaigns"
              iconName="campaigns-icon"
            />
          )}
          {showConfig.analytics && (
            <SidebarIconLink
              href="/dashboard/analytics"
              ariaLabel="Analytics"
              iconName="analytics-icon"
            />
          )}
          {showConfig.emergency && (
            <SidebarIconLink
              href="/dashboard/emergency"
              ariaLabel="Emergency"
              iconName="emergency-icon"
            />
          )}
        </div>
      </div>
      <div className="pb-6">
        <div className="flex flex-col items-center gap-4">
          {showConfig.settings && (
            <button
              onClick={() => window.location.assign("/dashboard/settings")}
              className="text-gray-700 hover:text-blue-500 p-2"
              aria-label="Settings"
            >
              <Settings size={20} />
            </button>
          )}
          <button
            onClick={() => window.location.assign("/sign-out")}
            className="text-gray-700 hover:text-red-500 p-2"
            aria-label="Sign Out"
          >
            <LogOut size={20} />
          </button>
        </div>
      </div>
    </div>
  );
}
