"use client";

import { forwardRef } from "react";
import { getCookie } from "cookies-next";
import { setCookie } from "cookies-next";
import { z } from "zod";
import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
} from "@/components/ui/select";
import { clearCookies } from "@/app/cookies/utils";

const TenantSelector = forwardRef<HTMLButtonElement>(() => {
  const tenantId = getCookie("tenantId");
  const allTenantMode = getCookie("allTenantMode") === "true";
  const tenants = z.array(z.object({ id: z.string(), name: z.string() }))
    .parse(JSON.parse(getCookie("tenants") || "[]"));

  if (tenants.length === 0) {
    clearCookies();
    return null;
  }

  const currentTenant = allTenantMode ?
    { id: "all", name: "All Tenants" } :
    tenants.find(t => t.id === tenantId) ?? { id: "all", name: "All Tenants" };

  return (
    <Select
      value={currentTenant.id}
      onValueChange={(value) => {
        if (value === "all") {
          setCookie("allTenantMode", "true");
        } else {
          setCookie("allTenantMode", "false");
          setCookie("tenantId", value);
        }
        window.location.reload();
      }}
    >
      <SelectTrigger
        className="flex items-center justify-center w-10 h-10 rounded-full transition-colors sidebar-icon-inactive hover:sidebar-icon-active [&>svg]:hidden"
      >
        <span className="text-lg font-bold">
          {currentTenant.id === "all" ? "*" : currentTenant.name.charAt(0).toUpperCase() || ""}
        </span>
      </SelectTrigger>
      <SelectContent align="center" className="w-[200px]">
        <SelectItem value="all">All Tenants</SelectItem>
        {tenants.map(tenant => (
          <SelectItem key={tenant.id} value={tenant.id}>
            {tenant.name}
          </SelectItem>
        ))}
      </SelectContent>
    </Select>
  );
});

TenantSelector.displayName = "TenantSelector";

export default TenantSelector;
